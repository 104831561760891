export const campaignInfoData = [
    {
        name: 'impressions',
        title: 'Impression',
        type: 'text',
    },
    {
        name: 'targetAudience',
        title: 'Target Audience',
        type: 'text',
    },
    {
        name: 'geoTargeting',
        title: 'Geo Targeting',
        type: 'text',
    },
    {
        name: 'otherTargeting',
        title: 'Other Targeting',
        type: 'text',
    },
    {
        name: 'startDate',
        title: 'Start Date',
        type: 'text',
    },
    {
        name: 'endDate',
        title: 'End Date',
        type: 'text',
    },
    {
        name: 'campaignKPI',
        title: 'Campaign KPI',
        type: 'text',
    },
    {
        name: 'landingPage',
        title: 'Landing Page',
        type: 'url',
    },
    {
        name: 'reportingDay',
        title: 'Reporting Day',
        type: 'string',
    },
    {
        name: 'creativesSpecs',
        title: 'Creatives Specs',
        type: 'string',
    },
    {
        name: 'additionalInfo',
        title: 'Additional Information',
        type: 'text',
    },
];
