import React, {useState, FC} from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import axios from 'axios';
import _ from 'lodash';

import Layout from '../../layout/main-empty';
import SEOComponent from '../../components/seo';
import {nullValidation, recheckNullValidation } from '../red-ventures-service-form';
import NewLogo from 'assets/images/logo.png';
import { overviewData } from '../../assets/data/red-ventures/overviewData';
import {campaignInfoData} from '../../assets/data/red-ventures/campaignInfoData';
import { conformation } from '../../assets/data/red-ventures/conformation';


const ResultPanel = styled.div`
    border: 2px solid black;
    margin: 10px 10px 20px 10px;
`;

const PanelHead = styled.div`
    display: flex;
    flex-direction: row;
`;

const ClientInfo = styled.div`
    padding: 10px;
    font-size:16px;    
    width: 85%;
`;

const ClientInfoItem = styled.div`
   padding-top: 5px;
`;

const ClientInfoTitle = styled.span`
   font-weight: 600; 
   padding-right: 15px;
`;

const Logo = styled.img`
    width: 10%;
    padding: 10px;
`

const PanelMain = styled.div`
   display: grid;
   grid-template-columns: 8% 8% auto 16% 12% 10% 7% 5% 12% 5%;
   @media screen and (max-width: 1024px){
        font-size: 12px;
   }
   @media screen and (max-width: 900px){
        font-size: 10px;
   }
   @media screen and (max-width: 767px){
        font-size: 9px;
   }
   @media screen and (max-width: 480px){
        font-size: 8px;
   }
`;

const PanelMainSection = styled.div`
    text-align: center;
`;

const PanelMainThread = styled.div`
    background-color: #1b1b1b;
    color: #fcfafa;
    @media screen and (max-width: 767px){
        padding: 2px;
   }
`;

const PanelMainEmpty = styled.div`
    background-color: #373434;
    height: 15px;
`;

const PanelMainContent = styled.div`
    color: black;
    padding: 5px;
    height: 20px;
    border-right: 1px solid black;
    @media screen and (max-width: 767px){
        padding: 2px;
        height: 15px;
   }
`;

const PanelBottom = styled.div`
    padding: 70px 10px 10px 10px;
    height: 460px;
    font-size: 13px;
`;

const BindingText = styled.div`
    font-weight: 600;
    margin: 10px 10px 10px 0px;
`;

const Signature = styled.div`
    margin: 40px 30% 40px 0px;
    line-height: 20px; 
    
`;

const SignatureItem = styled.div` 
    display: flex;
    flex-direction: row;
    justify-content: right;
    line-height: 30px;
    font-size: 14px;
    text-align: right; 
    labeL {
        padding-right: 15px;
    }
    input{
        text-align: left;       
        width: 50%;
        background-color: #eee; 
        border: hidden;
        border-bottom: 2px solid black;
    }
`;
const Error = styled.div`
    color: red;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-left: 30%;
    padding: 5px;
`;

const SubmitSucceed = styled.div`
    padding-top: 100px;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
`;

const ButtonGroup = styled.div`
    padding: 10px 10px 20px 10px;
    margin-left: 60%;
    button {
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 2px;
        border-radius: 7px;
        color: #e6e0db;
        width: 32%;        
    }
    .back {
        background-color: ${(props : ButtonType)=> props.children[0].props.disabled ? '#9a958a' : '#161615'}; 
    }
    .submit {
        background-color: ${(props : ButtonType)=> props.children[1].props.disabled ? '#9a958a' : '#161615'}; 
    }
`;

interface Input {
    [name : string]: string
};

interface ButtonType {
    disabled?: boolean;
    children: any[];
}

interface SignatureType {
    "Authorized Signature": string,
    "Printed Name": string, 
    "Title": string, 
    "Date": string
}

interface FormDataType {
    name: string,
    title?: string,
    type?: string,
    content?: string | string[]
};

const ServiceFormConformation : FC = () => {   
    const CPM = 12;

    const overviewInput = formDataHelper('overviewData', overviewData);
    const campaignInfo = formDataHelper('campaignInfo', campaignInfoData);
    const initialSignature = {
        "Authorized Signature": '',
        "Printed Name": '', 
        "Title": '', 
        "Date": ''
    };
    const [signatureInput, setSignatureInput] = useState<SignatureType>(initialSignature);
    const [requiredError, setRequiredError] = useState<string>('');

    const servicesData : FormDataType[] = [
        {
            name: 'strategy',
            title: 'STRATEGY', 
            content: 'Display'
        }, {
            name: 'goal',
            title:  'GOAL',
            content: campaignInfo.campaignKPI
        }, {
            name: 'targetAudience',
            title: 'TARGET AUDIENCE',
            content: campaignInfo.targetAudience
        }, {
            name: 'impressions',
            title: 'EST. IMPRESSIONS',
            content: campaignInfo.impressions 
        }, {
            name: 'startDate',
            title: 'START DATE',
            content: campaignInfo.startDate 
        }, {
            name: 'endDate',
            title: 'END DATE',
            content: campaignInfo.endDate
        }, {
            name:'pricing',
            title: 'PRICING',
            content: 'CPM' 
        }, {
            name: 'rate',
            title: 'RATE',
            content: '$12.00' 
        }, {
            name:'totalCost',
            title: 'TOTAL COST',
            content: parseFloat(overviewInput.budget).toLocaleString() 
        }, {
            name:'geo',
            title: 'Geo',
            content: campaignInfo.geoTargeting
        }
    ];
       
    const [formData, setFormData]= useState<FormDataType[]>(servicesData);

    const handleSignatureInput = (e: any) => {
        setSignatureInput ({
            ...signatureInput,
            [e.target.name]: e.target.value
        });
    }

    const handleSignatureInputLeave = (e : any) => {
        const index = parseInt(e.target.id);
        const newSignatureInput = Object.values(signatureInput);
        recheckNullValidation(newSignatureInput, requiredError, setRequiredError);
    }
 
    const onSubmit = (e: any) => {
        e.preventDefault();
        const failValidation = nullValidation(Object.values(signatureInput),'Signature Fields', setRequiredError);
        if(failValidation) return;

        axios.post('https://audigent.com/operation/red-ventures-service', { overviewInput, campaignInfo })
        .then( res => {
            setFormData([]);
            localStorage.setItem('formData',''); 
        })
        .catch(err => console.log(err));
    }

    const onBack = () => {
        navigate('/red-ventures-service-form'); 
    }

    return (
        <Layout mainBackgroundColor='#eee'>
            <SEOComponent
                title="Red Ventures Client Form"
                description="Red Ventures Client Form"
            />

            <ResultPanel>
                <PanelHead>
                    <ClientInfo>
                        <ClientInfoItem>
                            <ClientInfoTitle>Advertiser/Client Name:</ClientInfoTitle>{overviewInput !== {} && overviewInput['client'] ? overviewInput['client'] : ''}                      
                        </ClientInfoItem>                            
                         <ClientInfoItem>
                         <ClientInfoTitle>Campaign Name:</ClientInfoTitle>
                            {overviewInput !== {} && overviewInput['campaignName'] ? overviewInput['campaignName'] : ''}
                         </ClientInfoItem>                  
                    </ClientInfo>
                    <Logo src={NewLogo} alt ='logo'/>
                </PanelHead>                
                <PanelMain>
                    {
                        servicesData.map((d, i) => 
                            <PanelMainSection key = {d.title}>
                                <PanelMainThread>{d.title}</PanelMainThread>
                                <PanelMainEmpty />
                                <PanelMainContent>{d.content}</PanelMainContent>
                                <PanelMainEmpty />
                            </PanelMainSection>
                    )}                                 
                </PanelMain>
                <PanelBottom>
                    {
                        formData.length !== 0 ? 
                        <>
                        <div>{conformation.note}</div>
                        <BindingText>{conformation.bindingText}</BindingText>
                        <Signature>
                            {
                                ["Authorized Signature","Printed Name", "Title", "Date"].map((d,i) => 
                                <SignatureItem key={d}>
                                    <label htmlFor={d}>{`${d}: `}</label> 
                                    <input 
                                        value={signatureInput[d as keyof SignatureType]} 
                                        name={d}
                                        id={i.toString()}
                                        onChange={e=> handleSignatureInput(e)}
                                        onMouseLeave={e=>handleSignatureInputLeave(e)}
                                    />
                                </SignatureItem>)
                            }
                            { <Error key={`error-required`}>{requiredError}</Error> } 
                        </Signature>
      
                        <h3>TERMS AND CONDITIONS</h3>
                        {
                            conformation.terms.map((d, j) =>
                                <div key={`term${j}`}
                                    id={`term${j}`}
                                    dangerouslySetInnerHTML={{ __html: d.term}}
                                />
                        )}       
                        </>                     
                        : 
                        <SubmitSucceed>Great! You have successfully submitted your service form. </SubmitSucceed>
                    }
                </PanelBottom>
            </ResultPanel>
           
           <ButtonGroup>
                <button className='back' onClick={onBack} disabled={false}>Back</button>
                <button className='submit' onClick={onSubmit} disabled={formData.length===0}>Submit</button>
           </ButtonGroup>
            
        </Layout>
    );   
}

const formDataHelper = (key: string, dataSource: FormDataType[]) => {
    let oData = typeof window !== "undefined" ? localStorage.getItem(key) : null;

    let formInput : Input = {};

    dataSource.forEach ((d, i) => {
        formInput[d.name] = oData !== null && oData !== '' ?JSON.parse(oData)[i] : '';
    })
    return formInput;
}

export default ServiceFormConformation;

