export const conformation = {
    note: "Signatory must be a proprietor, a general partner or an officer of the company with authority to enter into contractual agreements. ",
    bindingText: "I/WE AGREE TO BE BOUND BY THE TERMS AND CONDITIONS GOVERNED BY THE IAB/AAAA STANDARD TERMS AND CONDITIONS.",
    
    terms: [{
        term: "1. Campaign will be tracked and billed using Predictive Pop, Inc d/b/a Audigent unless otherwise agreed upon in advance."
    },
    {
        term: "2. Audigent will provide ASM with weekly campaign reports."
    },
    {
        term: "3. Payment terms are <Strong>NET 30 DAYS</Strong> from the date of the invoice unless other terms are approved. Audigent will invoice amounts due monthly."
    },
    {
        term: "4. Payment method: Audigent sends monthly invoice on the 1st of following month. ASM will review and approve the invoice in 5 business days after invoice is received. "
    },
    {
        term: "5. Campaign Cancellation Policy: ASM may cancel this service after 48 hours written notice."
    }]   
};
